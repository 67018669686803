document.addEventListener('DOMContentLoaded', () => {
    const $subMenuArrows = document.querySelectorAll('.sub-menu__arrow')
    if ($subMenuArrows) {
        $subMenuArrows.forEach(($subMenuArrow) => {
            $subMenuArrow.addEventListener('click', (e) => {
                const $item = e.currentTarget
                const $itemParent = $item.parentElement
                $itemParent.classList.toggle('active')
            })
        })
    }

    const $menuBurger = document.querySelector('.page-header__burger')
    if ($menuBurger) {
        $menuBurger.addEventListener('click', (e) => {
            const $burger = e.currentTarget
            const $mobileMenu = document.querySelector('.main-nav')
            $burger.classList.toggle('active')
            $mobileMenu.classList.toggle('active')
        })
    }

    const $videos = document.querySelectorAll('.video')
    if ($videos) {
        $videos.forEach(($video) => {
            $video.addEventListener('click', (e) => {
                const $video = e.currentTarget
                const $target = e.target
                const $videoPreview = $video.querySelector('.video__preview')
                const $videoButton = $video.querySelector('.video__button')
                const $videoContainer = $video.querySelector('.video__container')
                const videoUrl = $video.dataset.url
                let iframe = document.createElement('iframe')
                iframe.setAttribute('allowfullscreen', '')
                iframe.setAttribute('allow', 'autoplay');
                iframe.setAttribute('src', videoUrl + '?rel=0&showinfo=0&autoplay=1')
                iframe.classList.add('video__media')

                $videoPreview.remove()
                $videoButton.remove()
                $videoContainer.appendChild(iframe)
            })
        })
    }

    const $promocodes = document.querySelectorAll('.promocode')
    if ($promocodes) {
        $promocodes.forEach(($promocode) => {
            $promocode.addEventListener('click', (e) => {
                const $item = e.currentTarget
                const $target = e.target
                const $promocodeButton = $item.querySelector('.promocode__button')
                const $promocodeButtonText = $promocodeButton.textContent
                const $promocodeContent = $item.querySelector('.promocode__value')

                if ($target === $promocodeButton) {
                    let tmp = document.createElement('textarea')
                    tmp.value = $promocodeContent.textContent
                    $item.appendChild(tmp)
                    tmp.select()
                    document.execCommand('copy')
                    tmp.remove()
                    $promocodeButton.textContent = 'Готово'
                    setTimeout(() => {
                        $promocodeButton.textContent = $promocodeButtonText
                    }, 3000)
                }
            })
        })
    }

    const $hideButtons = document.querySelectorAll('.hide-button')
    if ($hideButtons) {
        $hideButtons.forEach(($button) => {
            $button.addEventListener('click', (e) => {
                const $button = e.currentTarget
                document.location.href = $button.dataset.href
            })
        })
    }

    const $faqItems = document.querySelectorAll('.faq__item')
    if ($faqItems) {
        $faqItems.forEach(($faqItem) => {
            $faqItem.addEventListener('click', (e) => {
                const $faqItem = e.currentTarget
                const target = e.target
                const $faqQuestion = $faqItem.querySelector('.faq__question')
                const $faqAnswer = $faqItem.querySelector('.faq__answer')
                if (target === $faqQuestion) {
                    $faqAnswer.classList.toggle('active')
                }
            })
        })
    }

    const $toc = document.querySelectorAll('.toc')
    if ($toc) {
        $toc.forEach(($toc) => {
            $toc.addEventListener('click', (e) => {
                const $toc = e.currentTarget
                const $tocTitle = $toc.querySelector('.toc__title')
                if (e.target === $tocTitle) {
                    $toc.classList.toggle('active')
                }
            })
        })
    }

    const $scrollToTopButton = document.querySelector('.scroll-top__button')
    if ($scrollToTopButton) {
        $scrollToTopButton.addEventListener('click', (evt) => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        })
    }
})
